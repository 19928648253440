/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: frontgen
 * Version: 7.0.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* Affichage d'une image dans une popup */
function afficheImage(source) {
    // Ouverture du pop-up
    window.open(source,'pop','status=no,directories=no,toolbar=no,location=no,menubar=no,scrollbars=yes,resizable=yes');
}

/* Fonction utilisée dans la recherche avancée pour réinitialiser les formulaires */
function viderFormulaire(criteres) {
    criteres = criteres.split(";");
    var champReinit = "";
    var valeurChamp = "";

    for (var i=0; i < (criteres.length); i++) {
        champReinit = eval("document.forms['recherche_avancee']." + criteres[i].substring(0, criteres[i].indexOf("=")));
        valeurChamp = criteres[i].substring(criteres[i].indexOf("=")+1);

        if (champReinit) {

            var sType = champReinit.type;
            //bouton radio
            if (!sType) {
                for (var i = 0; i < champReinit.length; i++)
                    champReinit[i].checked = false;
            }
            //checkbox
            else if(sType == 'checkbox')
                champReinit.checked = false;
            //autres
            else
            champReinit.value = valeurChamp;
        }
    }
}


/* Fonction permettant d'intervertir l'id d'un élément avec un autre */
function switchId(ancienIdItem, nouvelIdItem) {
    var itemSwitch = window.document.getElementById(ancienIdItem);

    if (itemSwitch != null) {
        itemSwitch.id = nouvelIdItem;
    }
}
